import React from 'react'
import {graphql} from 'gatsby'
import EventList from './Events/EventList'

const EventIndex = ({
                    location,
                   data,
                   pageContext: {
                     nextPagePath,
                     previousPagePath,
                     currPage,
                     archivePath,
                     archiveTitle,
                     numPages,
                     offset,
                     postsPerPage,
                     totalResources,
                   },
                   }) => (
  <EventList backLink={location.pathname}
             events={data?.allWpPost?.nodes}
             nextPagePath={nextPagePath}
             previousPagePath={previousPagePath}
             currPage={currPage}
             archivePath={archivePath}
             archiveTitle={archiveTitle}
             numPages={numPages}
             offset={offset}
             postsPerPage={postsPerPage}
             totalResources={totalResources} />
)

export default EventIndex

export const pageQuery = graphql`
    query WordPressEventArchive($offset: Int!, $postsPerPage: Int!, $currDate: Date!) {
        allWpPost(
            sort: { fields: [eventInformation___startDate], order: ASC }
            limit: $postsPerPage
            skip: $offset
            filter: {
                resourceTypes: {nodes: {elemMatch: {slug: {eq: "events"}}}},
                eventInformation: {startDate: {gte: $currDate}}
            }
        ) {
            nodes {
                uri
                date(formatString: "MMMM DD, YYYY")
                title
                resourceTypes{
                    nodes {
                        slug
                    }
                }
                eventInformation {
                    startDate
                    endDate
                    time
                    registerLink
                    pricingShort
                    pricing
                    location
                    legalCredits
                    instructors
                }
                resourceInformation {
                    service {
                        ... on WpService {
                            title
                            uri
                            serviceTypes {
                                nodes {
                                    slug
                                    name
                                }
                            }
                        }
                    }
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: CONSTRAINED,
                                    aspectRatio: 1.59,
                                    width: 550
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
